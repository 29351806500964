<template>
<div >
  <div style="margin: 20px 0 0 20px;">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="my-correction">
    <div class="top">
      <div class="top-status">
        <div class="name">
          处理状态:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="curStatusIndex == index ? 'active' : ''" v-for="(item,index) in statusList" :key="item.id" @click="changeSearch(index,'curStatusIndex')">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="top-right">
        <Input v-model="keyword"
               :placeholder="$t('common_search_keyword')"
               clearable
               search
               :enter-button="true"
               @on-enter="changeKeyword"
               @on-search="changeKeyword"
        />
      </div>
    </div>
    <Table border :columns="columns" :loading="loading" :data="data" ></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>
  <Modal
      v-model="dataInfoModal"
      title="题目详情"
      width="1000px"
  >
    <DataInfo :dataId="curData.data_id" v-if="dataInfoModal"></DataInfo>
  </Modal>
  <Modal
      v-model="feedBackContModal"
      title="反馈内容"
  >
    <Richtxt :dataObj="{val:curData.err_message}" :isInline="true" :isDisabled="true"></Richtxt>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import DataInfo from '@/components/dataInfo.vue';
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "mycorrection.vue",
  data(){
    return {
      columns: [
        {
          title: this.$t('dataset_data_table_index'),
          width: 80,
          key:'id',
        },
        {
          title: '处理状态',
          key: 'status',
          render:(h,params)=>{
            return h('span',this.statusObj[params.row.status])
          }
        },
        {
          title: '试题标题',
          key: 'name',

        },
        {
          title: '反馈类型',
          key: 'title',
          render:(h,params)=>{
            return h('span',this.dataCorrectionTypes[params.row.type])
          }
        },
        {
          title: '反馈内容',
          key: 'err_message',
          render:(h,params)=>{
            return params.row.err_message? h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px',
                marginTop: '5px'
              },
              on: {
                click: () => {
                  // this.delete(params.row);
                  this.curData = params.row;
                  this.feedBackContModal = true;
                }
              }
            }, '查看反馈内容') : ''
          }
        },
        {
          title: '回复内容',
          key: 'reason',
        },
        {
          title: '提交时间',
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '回复时间',
          key: 'updated_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_data_table_op'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    // this.delete(params.row);
                    this.curData = params.row;
                    this.dataInfoModal = true;
                  }
                }
              }, '查看')
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      page:1,
      pageSize:10,
      total:0,
      keyword:'',
      typeObj:{},
      levelObj:{},
      statusObj:{},
      statusList:[],
      curStatusIndex:0,
      dataInfoModal:false,
      curData:{},
      dataCorrectionTypes:{},
      feedBackContModal:false,
    }
  },
  components:{
    DataInfo,
    Richtxt
  },
  created(){
    this.getList();
  },
  methods:{
    changeSearch(index,name){
      this[name] = index;
      this.getList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword
      };
      if(this.statusList.length){
        params.status = this.statusList[this.curStatusIndex].id;
      }else{
        params.status = 0;
      }

      this.api.dataset.datasetCorrectionList(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);
        // this.parentCategoryArr = parentCategoryArr;
        this.dataCorrectionTypes = res.data_correction_types;
        this.levelObj = res.data_levels;
        this.statusObj = res.statuses;

        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name,
          })
        }


      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.my-correction{
  margin: 20px;
  padding: 20px;
  background: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

  .top{
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-status{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
